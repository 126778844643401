import Vue from 'vue'
import i18n from "../i18n/i18n"

export const handleError = (error) => {
  let title = ''
  let message = ''
  if (error.code === 'ECONNABORTED') {
    title = i18n.t('search_timeout_title')/*搜尋逾時*/
    message = i18n.t('search_timeout_message')/* 請縮小搜尋範圍 */
  } else {
    if (error.response) {
      // 伺服器回應但狀態碼出錯，例如 404 或 500
      title = i18n.t('error')/*錯誤*/
      message = `${error.response.status}: ${error.response.data.message}`
    } else {
      // 其他錯誤處理
      title = i18n.t('error')/*錯誤*/
      message = error.message
    }
  }

  Vue.prototype.$notify.error({
    title,
    message
  })
}