// 定義地圖預設值
// * 中心點
// * Zoom In 最大倍率

// export const Bovia = {
//   center: { lat: 24.807824129248665, lng: 121.03832092621745 },
//   zoom: 15
// }
export const Taiwan = { center: { lat: 23.582594, lng: 120.585528 }, zoom: 8 } // 台灣地理中心
export const WholeWorld = { center: { lat: 0, lng: 0 }, zoom: 2.8 }

export const LoginMapCenter = JSON.parse(JSON.stringify(Taiwan))

// 當marker距離很接近時，放大倍率大增，需要再調整成zoom=15
export const defaultZoom = 15  // 地圖預設縮放等級0~21
export const sosZoom = 18  // 緊急事件或追車事件時的縮放等級

const genUrl = () => {
  // for security
  let k1 = 'AIzaSyAjlV'
  let k2 = 'LN8XOg2V3n'
  let k3 = 'y6jyeF7jYE'
  let k4 = 'KyKMTrgFk'

  return `${k1}${k2}${k3}${k4}`
}

export const getMapUrl = () => {
  return genUrl()
}
