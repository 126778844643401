import Vue from 'vue'
import i18n from "../../i18n/i18n"
import { apiGetVideoGPS } from '@/api/index.js'
import { apiVideoSearch, apiVideoCount } from '@/api/v4'
import crypto from '@/utils/crypto'
import { handleError } from '@/utils/handleError.js'

const initialState = () => ({
  // 暫存最新三筆案由於localStorage
  queryCauses: localStorage.getItem('queryCausesVideo') 
    ? JSON.parse(crypto.decrypt(localStorage.getItem('queryCausesVideo'))) : [], // 案由
  queryParams: {},
  searchLoading: false,
  videoTotal: 0, // 搜尋的總video數
  historyVideoList: [], // 僅存放該次搜尋的video, ex:20筆
  videoCurrentPage: 1, // 目前分頁所在頁數，從1開始
  pageSize: 100, // 每個分頁video數量
  beforeId: null, // 記錄目前分頁最新 video id, 為該分頁最大的id
  afterId: null, // 記錄目前分頁最舊 video id, 為該分頁最小的id
  videoIndex: 0, // video在目前分頁的index, 從0開始
  videoFocusIndex: -1, // 點擊事件卡片的index，初始值設為-1, 為了watch其變為０時也能更新focus
  hlsVideoList: [], // 暫存 HLS video list
  videoGpsMap: {}, // 紀錄 video GPS 位置資料
})

const state = initialState()

const getters = {
  modalVideo(state) {
    return state.historyVideoList[state.videoIndex]
  },
  totalVideoNo(state) {
    // 目前影片在所有影片的編號，從１開始
    return (state.videoCurrentPage - 1) * state.pageSize + state.videoIndex + 1
  }
}

const mutations = {
  resetState(state) {
    const initial = initialState()
    Object.keys(initial).forEach(key => { state[key] = initial[key] })
  },
  addQueryCause(state, cause) {
    let idx = state.queryCauses.findIndex(item => item === cause.trim())
    if (idx === -1) {
      state.queryCauses.unshift(cause.trim())
      state.queryCauses = state.queryCauses.slice(0, 3) // 暫存最新三筆
      localStorage.setItem('queryCausesVideo', crypto.encrypt(JSON.stringify(state.queryCauses)))
    }
  },
  updateQueryParams(state, payload) {
    state.queryParams = payload
  },
  updateSearchLoading(state, value) {
    state.searchLoading = value
  },
  updateVideoTotal(state, value) {
    state.videoTotal = value
  },
  updateHistoryVideoList(state, list) {
    state.historyVideoList = list
    // add uid for each video
    state.historyVideoList.forEach((video) => {
      video.uid = `video-${video.id}`
    })
  },
  updateVideoCurrentPage(state, value) {
    state.videoCurrentPage = value
  },
  updateBeforeId(state, value) {
    state.beforeId = value
  },
  updateAfterId(state, value) {
    state.afterId = value
  },
  updateVideoIndex(state, value) {
    state.videoIndex = value
  },
  updateVideoFocusIndex(state, value) {
    state.videoFocusIndex = value
  },
  updateHlsVideoList(state, list) {
    state.hlsVideoList = list
  },
  updateVideoGpsMap(state, payload) {
    state.videoGpsMap = payload
  }

}

const actions = {
  async getVideoGpsMap({ commit, state }) {
    try {
      const videoGpsMap = {}
      for (const video of state.historyVideoList) {
        const res = await apiGetVideoGPS(video.id)
        videoGpsMap[video.id] = res.data.gpsList || []
      }
      commit('updateVideoGpsMap', videoGpsMap)
    } catch (error) {
      handleError(error)
      commit('updateVideoGpsMap', {})
    }
  },
  // 使用v4 API
  async videoCount({ commit, state }) {
    try {
      const params = { ...state.queryParams}
      const res = await apiVideoCount(params)
      commit('updateVideoTotal', res.data.total)
    } catch (error) {
      handleError(error)
      commit('updateVideoTotal', 0)
    }
  },
  async searchVideo({ commit, state, dispatch }, payload) {
    commit('updateSearchLoading', true)
    if (state.queryParams.purpose)
      commit('addQueryCause', state.queryParams.purpose) // 將案由暫存至localStorage

    try {
      // 計算時間差
      const params = {
        ...state.queryParams,
        limit: state.pageSize,
        desc: 1,
      }

      if (payload.page === 1) {
        dispatch('videoCount')
      } else {
        if (payload.page > state.videoCurrentPage) {
          delete params.afterId
          params.beforeId = state.afterId // 取得<beforeId的資料
        } else {
          delete params.beforeId
          params.afterId = state.beforeId // 取得>afterId的資料
          params.desc = 0 // 由舊到新 --> 再取100筆
        }
      }
      
      const res = await apiVideoSearch(params)
      // desc 1: 由新到舊, 0: 由舊到新
      if (params.desc === 0) {
        res.data.videoList.reverse() // 由舊到新，需反轉
      }
      commit('updateHistoryVideoList', res.data.videoList)
      const len = res.data.videoList.length
      if (len > 0) {
        commit('updateBeforeId', res.data.videoList[0].id)
        commit('updateAfterId', res.data.videoList[len - 1].id)

        // 歷史影片須從API取得GPS資料
        dispatch('getVideoGpsMap')

        // 若是回到前一分頁，eventIndex更新為前一分頁最後一筆，下一頁則是第一筆
        let eventIndex = payload.page < state.videoCurrentPage ? len - 1 : 0 
        commit('updateVideoCurrentPage', payload.page)
        commit('updateVideoIndex', eventIndex)
        commit('updateVideoFocusIndex', eventIndex)
      } else {
        Vue.prototype.$notify.warning({
          title: i18n.t('search_hint')/*提示*/,
          message: i18n.t('search_hint_nodata')/* 查無資料 */
        })
      }
      
    } catch (error) {
      handleError(error)
      commit('updateHistoryVideoList', [])
      commit('updateVideoTotal', 0)
    } finally {
      commit('updateSearchLoading', false)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}